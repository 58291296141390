export const breakpoints = [480, 768, 992, 1200, 1600];

export const theme = {
  breakpoints: breakpoints.map((v) => `${v}px`),
  spacing: [0, 8, 16, 24, 32, 40, 48, 56, 64],
  fonts: {
    poppins: '"Poppins", sans-serif',
    domine: '"Domine", serif',
  },
  colors: {
    black: "#19202A",
    white: "#fff",
    green: { default: "#009100", darker: "#006600", lighter: "#00bb00" },
    grey: "#828282",
    heading: "#56597A",
    text: "#919299",
    error: "#ed4b51",
  },
};

const [sm, md, umd, lg, xlg] = breakpoints;

theme.breakpoints.sm = sm;
theme.breakpoints.md = md;
theme.breakpoints.umd = umd;
theme.breakpoints.lg = lg;
theme.breakpoints.xlg = xlg;
